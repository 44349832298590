import React, { useEffect, useState, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import { Input, Button } from 'antd';
import Layout from '../components/Layout';
import Container from '../components/Container';
import Logo from '../images/tool-logo.svg';
import BallImg from '../images/ball.svg';
import BgSvg from '../images/canvas-bg.svg';
import LogoImage from '../images/logo-211201.svg';
import track from '../utils/track';
import ApplyDialogContext from '../context/applyDialog';
import { getDataFromCpm } from '../utils/common';

const { Search } = Input;

function dist(x1, y1, x2, y2) {
  const a = x1 - x2;
  const b = y1 - y2;
  return Math.sqrt(a * a + b * b);
}

function background(clr, ctx, canvas) {
  ctx.save();
  ctx.fillStyle = ctx.createPattern(clr, 'repeat');
  ctx.fillRect(0, 0, canvas.width, canvas.height);
  ctx.restore();
}

function Circle(item, idx, configMap = {}) {
  const { x, y, r, color, shape = 'round', color1 = '#fff', direction = 'vertical' } = item;
  const that = this;
  that.x = x;
  that.y = y;
  that.origX = x;
  that.origY = y;
  that.rds = r;
  that.currRds = 0;
  that.idx = idx;
  that.durAnimation = 2;
  that.durProgress = 0;
  that.shape = shape;
  const { logoLoaded, setLogoLoaded, img, ctx } = configMap;
  this.logoLoaded = logoLoaded;

  that.rectWidth = 0;
  that.rectX = 0;
  that.rectY = 0;
  that.rectXOffsetFactor = 0.5;
  that.rectYOffsetFactor = 0.5;
  that.rectWidthDivideFactor = 1;

  that.drawCircle = function (drawParam) {
    if (shape === 'logo') {
      if (this.logoLoaded) {
        ctx.drawImage(img, that.x, that.y, 2 * that.rds, 2 * that.rds);
      } else {
        img.onload = function () {
          ctx.drawImage(img, that.x, that.y, 2 * that.rds, 2 * that.rds);
          setLogoLoaded(true);
        };
      }
    } else {
      ctx.beginPath();
      ctx.strokeStyle = 'transparent';
      let lg;
      if (shape === 'round') {
        ctx.arc(that.x, that.y, that.currRds, 0, Math.PI * 2, false);
        if (direction === 'horizontal') {
          // 水平径向渐变
          lg = ctx.createLinearGradient(
            that.x - that.currRds,
            that.y - that.currRds,
            that.x + that.currRds,
            that.y - that.currRds
          );
        } else {
          // 垂直径向渐变
          lg = ctx.createLinearGradient(
            that.x - that.currRds,
            that.y - that.currRds,
            that.x - that.currRds,
            that.y + that.currRds
          );
        }
        lg.addColorStop(0, color);
        lg.addColorStop(1, color1);
        ctx.fillStyle = lg;
        ctx.fill();
      } else if (shape === 'half') {
        ctx.arc(that.x, that.y, that.currRds, 1.8333 * Math.PI, 0.8333 * Math.PI, false);
        lg = ctx.createLinearGradient(
          that.x + that.currRds * Math.cos(15),
          that.y + that.currRds * Math.sin(15),
          that.x - that.currRds * Math.cos(15),
          that.y - that.currRds * Math.sin(15)
        );
        lg.addColorStop(0, color1);
        lg.addColorStop(1, color);
        ctx.fillStyle = lg;
        ctx.fill();
      } else if (shape === 'triangle') {
        const p = [
          [that.x + 70 * drawParam, that.y - 150 * drawParam],
          [that.x + 180 * drawParam, that.y - 25 * drawParam],
          [that.x, that.y],
        ];
        const startPoint = [(p[0][0] + p[p.length - 1][0]) / 2, (p[0][1] + p[p.length - 1][1]) / 2];
        ctx.moveTo(...startPoint);
        p.forEach((pItem, i) => {
          if (i === p.length - 1) {
            ctx.arcTo(...p[p.length - 1], ...p[0], 6);
          } else {
            ctx.arcTo(...pItem, ...p[i + 1], 6);
          }
        });
        // 径向渐变
        lg = ctx.createLinearGradient(that.x + 35 * drawParam, that.y - 75 * drawParam, that.x + 180 * drawParam, that.y);
        lg.addColorStop(0, color);
        lg.addColorStop(1, color1);
        ctx.fillStyle = lg;
        ctx.fill();
      } else if (shape === 'ring') {
        // 垂直径向渐变
        ctx.lineWidth = 35 * drawParam;
        lg = ctx.createLinearGradient(
          that.x - that.currRds,
          that.y - that.currRds,
          that.x - that.currRds,
          that.y + that.currRds
        );
        lg.addColorStop(0, color);
        lg.addColorStop(1, color1);
        ctx.strokeStyle = lg;
        ctx.arc(that.x, that.y, that.currRds, 0, Math.PI * 2, false);
      }
      ctx.stroke();
      ctx.save();
      ctx.restore();
    }
  };

  that.drawRect = function () {
    ctx.save();
    ctx.translate(that.x, that.y);
    ctx.restore();
  };

  that.updateAnimationValues = function () {
    if (that.durProgress < that.durAnimation) {
      if (that.currRds !== that.rds) {
        that.currRds = that.rds;
        that.rectWidth = that.currRds / that.rectWidthDivideFactor;
        that.rectX = -that.currRds * that.rectXOffsetFactor;
        that.rectY = -that.currRds * that.rectYOffsetFactor;
      }
      that.durProgress += 0.008;
    }
  };
}

// 步骤二中的小球
function Ball(logo2Loaded, ballImag, ctx2, setLogo2Loaded, canvas2) {
  this.a = true;
  this.b = true;
  this.r = 50;
  this.ballColor = { color: '#33B5E5' };
  this.vx = 50;
  this.vy = 50;
  this.rotate = 0;
  this.ispeed = 1;
  this.ispeed2 = 2;
  this.logoLoaded = logo2Loaded;
  this.drawCircle = function (drawParam) {
    const maxWidth = canvas2.width;
    const maxHeight = canvas2.height;
    const _r = this.r * drawParam;
    const ispeed = this.ispeed * drawParam;
    const ispeed2 = this.ispeed2 * drawParam;
    ctx2.beginPath();
    if (this.a) {
      this.vx += ispeed;
      if (this.vx >= maxWidth - 2 * _r) {
        this.a = false;
      }
    } else {
      this.vx -= ispeed;
      if (this.vx <= 0) {
        this.a = true;
      }
    }

    if (this.b) {
      this.vy += ispeed2;
      if (this.vy >= maxHeight - 2 * _r) {
        this.b = false;
      }
    } else {
      this.vy -= ispeed2;
      if (this.vy <= 0) {
        this.b = true;
      }
    }
    ctx2.save();
    ctx2.translate(this.vx + _r, this.vy + _r);
    ctx2.rotate(Math.PI * this.rotate);
    this.rotate += 0.006;
    if (this.rotate >= 360) {
      this.rotate = 0;
    }
    ctx2.translate(-(this.vx + _r), -(_r + this.vy));
    ctx2.clearRect(0, 0, maxWidth, maxHeight);
    ctx2.drawImage(ballImag, this.vx, this.vy, 2 * _r, 2 * _r);
    ctx2.restore();
  };
}

const Arrow = () => {
  return (
    <div className="arrow-wrapper">
      <div className="point" />
      <div className="line" />
    </div>
  );
};

// 五张卡片数据配置
const CARDS_DATA = [
  {
    id: 'card-1',
    title: '别找了，你要的xxx都在这里了',
    downLoad: false,
    tag: '1',
  },
  {
    id: 'card-2',
    title: '购买xxx之前，您应该先回答这10个问题',
    downLoad: false,
    tag: '2',
  },
  {
    id: 'card-3',
    title: '为什么我会选择xxx',
    downLoad: false,
    tag: '3',
  },
  {
    id: 'card-4',
    title: '大部分关于xxx的文章我都不愿意多看一眼，这篇除外',
    downLoad: false,
    tag: '4',
  },
  {
    id: 'card-5',
    title: 'xxx实施指南',
    downLoad: false,
    tag: '5',
  },
  {
    id: 'card-6',
    title: '你想要的好标题都在这',
    tag: 'All',
    downLoad: true,
  },
];
const CARDS_DATA1 = [
  {
    id: 'card-1',
    title: 'xxx面临的最大问题，以及如何解决',
    downLoad: false,
    tag: '1',
  },
  {
    id: 'card-2',
    title: '关于改进xxx的7个建议',
    downLoad: false,
    tag: '2',
  },
  {
    id: 'card-3',
    title: 'xxx实施指南',
    downLoad: false,
    tag: '3',
  },
  {
    id: 'card-4',
    title: '10个关于xxx的微信公众号',
    downLoad: false,
    tag: '4',
  },
  {
    id: 'card-5',
    title: '购买xxx之前，您应该先回答这10个问题',
    downLoad: false,
    tag: '5',
  },
  {
    id: 'card-6',
    title: '你想要的好标题都在这',
    tag: 'All',
    downLoad: true,
  },
];
const CARDS_DATA2 = [
  {
    id: 'card-1',
    title: '购买xxx之前，您应该先回答这10个问题',
    downLoad: false,
    tag: '1',
  },
  {
    id: 'card-2',
    title: '如何向小白解释xxx？',
    downLoad: false,
    tag: '2',
  },
  {
    id: 'card-3',
    title: '做对这件事，有效提升xxx',
    downLoad: false,
    tag: '3',
  },
  {
    id: 'card-4',
    title: 'xxx的下一个大事件',
    downLoad: false,
    tag: '4',
  },
  {
    id: 'card-5',
    title: '营销人员必须认识的xxx问题',
    downLoad: false,
    tag: '5',
  },
  {
    id: 'card-6',
    title: '你想要的好标题都在这',
    tag: 'All',
    downLoad: true,
  },
];

const Skeleton = () => {
  return (
    <div className="skeleton-wrapper">
      <div className="skeleton-item" />
      <div className="skeleton-item" />
      <div className="skeleton-item" />
      <div className="skeleton-item" />
      <div className="skeleton-item" />
    </div>
  );
};

const TITLE_LIST = [
  '别找了，你要的xxx都在这里了',
  '如何看待xxx？',
  'xxx年度盘点',
  'xxx：期望与现实',
  'xxx的下一个大事件',
  'xxx面临的最大问题，以及如何解决',
  'xxx最值得关注的10位大牛',
  '关于改进xxx的7个建议',
  '认识xxx行业的雷布斯',
  '是什么在阻碍xxx的发展？',
  '关于xxx的6个常见误解',
  '营销人员必须认识的xxx问题',
  '关于xxx您应该避免的5个雷区',
  '做对这件事，有效提升xxx',
  '关于xxx的10个最佳App推荐',
  '您在xxx上犯下的7个可怕错误',
  '三天时间全面改善xxx',
  '为什么没人在乎xxx？',
  '如何成为xxx领域的专家？',
  '关于xxx，这7点你必须要知道',
  '全面提升xxx的11种方法',
  '10个关于xxx的微信公众号',
  '为什么你无法提升xxx效果',
  '关于xxx的20条深刻见解',
  '只要10分钟，xxx入门',
  '关于xxx的15个意想不到的数据',
  '为什么你需要花更多的时间在xxx',
  'xxx：这并不像您想象的那么难',
  '如何解决xxx问题？',
  'xxx年度大趋势解析',
  '如何看待xxx问题？',
  'xxx领域发生的最具创新性的事件',
  '8个关于xxx的最新趋势',
  '马云可以教给我们有关xxx的5件事',
  '如何向小白解释xxx？',
  '雷军可以教给我们什么关于xxx的知识',
  '关于xxx您可能会错过的7个趋势',
  'xxx入门者指南',
  'xxx每个人都应该使用的5种工具',
  '关于xxx的30条段子',
  'xxx，从历史到未来',
  'xxx，一个简单的定义',
  '忽略xxx的10个原因',
  '为什么我会选择xxx',
  '展望未来：十年后的xxx会是怎么样？',
  '关于xxx的6本书，您应该仔细阅读',
  '你想知道关于xxx的所有信息，都在这里了',
  '关于xxx，每个人都应该知道的17个术语',
  '为什么你需要持续专注于改善xxx？',
  '关于xxx的5个丑陋真相',
  '如何比你的领导更懂xxx？',
  'xxx实施指南',
  'xxx白皮书免费下载',
  '关于xxx的终极术语大全',
  '时光倒流：20年前人们如何看待xxx',
  '关于xxx的25个令人惊讶的事实',
  '做好xxx，只需要做对这7件事',
  '关于xxx的10条最佳建议',
  'xxx到底是什么？',
  '您确定您的xxx做对了吗？',
  '购买xxx之前，您应该先回答这10个问题',
  '如何做xxx？',
  '什么是xxx？为什么如此重要？',
  '建立成功xxx的5个步骤',
  '知道这6点，xxx再也不是问题',
  '万万没想到，xxx还可以这么玩',
  '如果你不知道xxx，至少你应该先看看这篇文章',
  '手把手教你如何做xxx',
  '这篇文章一定要看完，跟你的xxx有关',
  '还在为xxx发愁？',
  '别让xxx拖累了你的KPI',
  '同样是xxx，为什么结果差别那么大？',
  '任正非最吓人的10句话，原来对xxx也有启示',
  '震惊，原来xxx可以这么做',
  '这么做xxx就毁了，赶紧改正为时不晚',
  'xxx是做给自己的，别奢望服务商都懂你',
  '仅需7个技巧，轻松掌握xxx',
  '从零走向专业，xxx新人必须掌握的10个高效方法',
  '一个思维习惯，让xxx十拿九稳',
  '3天成为xxx专家，我是怎么做到的？',
  '为什么你看了那么多干货文章，缺仍然做不好xxx？',
  '关于xxx，92%的人都做错了',
  '这就是你要的xxx',
  '50%的xxx都是失败的，为什么？',
  '大部分关于xxx的文章我都不愿意多看一眼，这篇除外',
  '如何快速理解xxx？',
  '7个技巧，提升你的xxx',
  '关于xxx的最新提醒，你必须知道！',
  '深度解读xxx，快速获取xxx的17个技巧',
  '做对这7件事，显著提升xxx效果',
  '关于xxx，年薪20万与年薪200万看法有何不同？',
  'xxx小白，两个月搞定2000万xxx项目',
  '那些年，被xxx虐过的营销人',
  '自从做了xxx，我就没睡过一天好觉',
  '你以为懂xxx，其实你什么都不懂',
  '对于xxx，为什么领导比你还要急？',
  '最全的xxx知识都在这里了',
  '连马云都说好的xxx方法',
  '成功的xxx是怎样的体验？',
  'xxx不成功是因为没经验，错！',
  '真正的xxx高手，会这样实施',
  '不懂xxx是正常的，不懂装懂是要命的',
  '备好小本子，你要的xxx精华都在这里',
  '他纵横职场20年，却败在了xxx项目',
  '为了完成xxx的KPI，我竟干出这种事',
  '终于！我发现了xxx成功的秘诀',
  '节省100万元：原来xxx还可以这么做',
  '当我宣布要开始做xxx时，老板哭了',
  '当老板宣布要开始做xxx时，我哭了',
  '双十一，3折秒杀，你想要的xxx都在这里',
  '大家都在看，xxx还可以这么玩',
  '简直了，xxx原来可以这样',
  '有变化！xxx的最新解读来了',
  'xxx出最新版块了，快来抢鲜',
  '你知道吗？xxx还可以这样',
  '真庆幸，你这么早认识了xxx',
  '心动！最新的xxx',
  '可怕，不知道xxx原来这么丢人',
  '解读，为什么xxx这么香？',
  '与其浑浑噩噩，不如了解一下xxx',
  'xxx改变世界',
  '开始做xxx吧，你不会后悔',
  '人人都知道xxx，为什么只有我做的最好',
  '说了这么多年，xxx到底能给我们带来什么改变？',
  '一张图带你了解xxx',
  '独家，xxx的最全解读',
  '这锅，xxx就不背了啊',
  'xxx怎么了',
  'xxx为啥又火了？',
  '还在惊讶xxx？这个xxx都被抢光了',
  '不知道xxx，我有罪',
  '人人都知道的xxx，究竟是不是一个骗局',
  '我们把xxx都想错了',
  '你对于xxx的认知，可能都是错的',
  '为什么说xxx对你而言是最重要的？',
  'xxx的十大最新发展趋势',
  '如何重塑xxx？',
  '没有xxx，可能死不了，但大概率活不好',
  'xxx能有什么坏心眼……',
  '数据告诉你，不用xxx的人都怎么样了',
  '谁在赌xxx的未来？',
  '喧嚣与沉寂：xxx的这十年',
  '薛定谔的xxx',
  'xxx稳住还能赢',
  'xxx没用？不，是你没用！',
  '有了xxx，我不说人话',
  '离开xxx的人都后悔了',
  'xxx，过去、现在和未来',
  'AI与xxx，你没能想到的',
  '再见吧，xxx！',
  'xxx界的拼多多',
  '揭开xxx的“真面目”',
  '一段涉及xxx的秘史',
  '你好，xxx',
  'xxx到底是个什么东西？',
  '关于xxx的五大幻觉，你中了哪一招？',
  '开始了解xxx吧，现在还来得及',
  '破解！xxx的迷局',
  '如何假装很懂xxx？',
  '为了xxx，我有多敢花钱？',
  '谁才是xxx最后的大赢家',
  'xxx之后，我们还能走向何方？',
  'xxx沉浮二十年',
  '又双叒叕来了，这回是xxx',
  '最好的xxx，TOP1',
  '必备的xxx采购清单来了',
  '首发，xxx知识大全',
  '真正的xxx，你也许从未想过',
  '今天，我听到一个不可告人的xxx潜规则',
  '对不起，xxx就是这么任性',
  '看了这部高智商电影，终于知道xxx的奥秘',
  '时代变化太快，当我知道xxx的时候，规则就变了',
  '别看了，告诉你也学不会',
  '为了xxx，我竟然做了这事',
  '你是如何被xxx拖入深渊的',
  '竟然这样理解xxx？我上去就是一耳光',
  '明人不说暗话，今天要聊的是xxx',
  '努力是没有用的，不懂这个道理，你永远不会理解xxx',
  '做不好xxx的真正原因：你根本就没有想到',
  '因为xxx，我升职加薪',
  '没错，这就是xxx',
  '这些年，xxx的套路，你都经历了么',
  '关于xxx，这一次，我真的不想解释了',
  '了解xxx？先从这里开始',
  '没有优势就是xxx最大的优势',
  'xxx，我的建议也许是不',
  '一文看懂xxx的前世今生',
  '为什么xxx从人见人爱变成了人人喊打',
  'xxx，没人稀罕了',
  'xxx错了么？',
  'xxx，打钱！',
  '揭秘：xxx之困',
  '把命交给xxx，你放心吗？',
  '值得收藏：一本关于xxx的手册',
  'xxx，终于要变化了！',
  '有xxx了不起么？对，了不起！',
  'xxx，醒醒！',
  '最后的xxx，尊严还是命运',
  'xxx的游戏，你玩不起',
  '知道xxx≠牛逼，不知道xxx，一定不牛逼',
];

const CardItem = ({
  title,
  id,
  tag,
  downLoad,
  value,
  index,
  fake,
  moving,
  animate,
  movingDirection,
}) => {
  // 下载标题列表文件
  const exportFile = () => {
    const sourceHTML = `\ufeff${TITLE_LIST.map((item) => `${item.replace(/xxx/g, value)}`).join('\n')}`; // '\ufeff标题\ntitle\n';
    const source = `data:application/vnd.ms-excel;charset=utf-8,${encodeURIComponent(sourceHTML)}`;
    const fileDownload = document.createElement('a');
    document.body.appendChild(fileDownload);
    fileDownload.href = source;
    fileDownload.download = 'yourtitles.csv';
    fileDownload.click();
    document.body.removeChild(fileDownload);
  };

  const { setVisible, setModalType } = useContext(ApplyDialogContext);
  // 下载标题按钮点击事件
  const onDownloadClick = () => {
    // 从 cpm 获取用户同意信息
    const enabledCustomerCookie = getDataFromCpm('网站必须');
    const cookies = document.cookie;
    if (enabledCustomerCookie && cookies.includes('cl_info_uploaded')) {
      exportFile();
      return;
    }
    track({
      name: '申请试用',
      content: '小工具-营销主题生成器',
      current: 'tool-titles',
    });
    setVisible(true);
    setModalType('titles-tool');
  };
  if (fake) {
    return <div className={`card-item ${id} ${index > -1 ? `index-${index}` : 'hide'} fake`} />;
  }
  return (
    <div className={`card-item ${id} ${animate ? 'animate' : ''} ${moving ? `moving ${movingDirection}` : ''} `}>
      <div className="left-bg">
        <div className="tag" />
        <div className="tag-text">{tag}</div>
      </div>
      <div className="card-content-wrapper">
        {downLoad ? (
          <>
            <h3>{title}</h3>
            <Button type="primary" onClick={() => onDownloadClick(value)}>解锁200个主题创意</Button>
          </>
        ) : (
          <>
            <p className="skeleton-title">{title.replace(/xxx/g, value)}</p>
            <Skeleton />
          </>
        )}
      </div>
    </div>
  );
};

CardItem.propTypes = {
  title: PropTypes.string,
  id: PropTypes.string,
  tag: PropTypes.string,
  value: PropTypes.string,
  index: PropTypes.number,
  downLoad: PropTypes.bool,
  fake: PropTypes.bool,
  moving: PropTypes.bool,
  movingDirection: PropTypes.string,
  animate: PropTypes.bool,
};

let downloadCounts = 0;

export default () => {
  let circles = [];
  let ball = null;
  const [logoLoaded, setLogoLoaded] = useState(false);
  const [logo2Loaded, setLogo2Loaded] = useState(false);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [canvas, setCanvasEle] = useState(null);
  const [canvas2, setCanvasEle2] = useState(null);

  const input = useRef();
  const [value, setValue] = useState('');
  const onChange = (e) => {
    setValue(e.target.value);
  };

  const [error, setError] = useState(false);
  const [movingDirection, setMovingDirection] = useState('');
  const [cardsWrapperVisible, setVisible] = useState(false);
  const [cardsWrapperUnshown, setCardsWrapperShown] = useState(true);
  const [prevCardIndex, setPrevCardIndex] = useState(0);
  const [currentCardIndex, setCurrentCardIndex] = useState(0);

  let img;
  let bgSvg;
  let ballImag;
  let mouseX = 0;
  let mouseY = -100;

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsMobile(false);
    } else {
      setIsMobile(true);
    }
  }, []);
  const topMargin = 0; // isMobile ? 50 : 68;

  function getMousePos(block, evt) {
    block = block || document.getElementsByTagName('body')[0];
    const rect = block.getBoundingClientRect();
    return {
      x: evt.clientX - rect.left,
      y: evt.clientY - rect.top,
    };
  }

  function mouseMove(evt) {
    const mousePos = getMousePos(canvas, evt);
    mouseX = mousePos.x;
    mouseY = mousePos.y;
  }

  function setCanvas(animationFrame) {
    img = new Image();
    img.src = Logo;
    ballImag = new Image();
    ballImag.src = BallImg;
    bgSvg = new Image();
    bgSvg.src = BgSvg;
    if (!canvas) {
      return;
    }
    const ctx = canvas.getContext('2d');
    const ctx2 = canvas2.getContext('2d');
    const w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const h = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    // const { clientWidth, clientHeight } = document.body;
    setWidth(w);
    setHeight(h - topMargin > 400 ? h - topMargin : 400);

    // 浮动元素：位置，形状，色彩
    const oriPositions = [
      {
        x: 260,
        y: 252,
        r: 140,
        index: 0,
        color: '#FF558B',
        color1: '#FF91AD',
        direction: 'horizontal',
      }, // 左上大
      {
        x: 150,
        y: 162,
        r: 50,
        index: 1,
        color: '#5B98FF',
        color1: '#89C7FB',
        direction: 'horizontal',
      }, // 左上小
      {
        x: width - 300,
        y: 0,
        r: 70,
        index: 2,
        color: '#7471FC',
        shape: 'ring',
        color1: '#4FC3E7',
      }, // 右上的环
      {
        x: width - 280,
        y: height * 0.5 + 60,
        r: 180,
        index: 3,
        color: '#B6FC9A',
        color1: '#85E2EB',
      }, // 右大
      {
        x: width - 230,
        y: height * 0.5 - 70,
        r: 90,
        index: 4,
        color: '#FDE592',
        color1: '#FE9E65',
      }, // 右小
      {
        x: width * 0.5 + 70,
        y: height * 0.5 + 90,
        r: 35,
        index: 5,
        color: '#152A3B',
        shape: 'logo',
      }, // logo
      {
        x: width * 0.5 - 120,
        y: 220,
        r: 30,
        index: 6,
        color: '#CDEE9B',
        color1: '#9AE988',
      }, // 中上
      {
        x: 460,
        y: height - 214,
        r: 110,
        index: 8,
        color: '#04C3CE',
        color1: '#43E0C1',
      }, // 左下圆
      {
        x: 260,
        y: height - 202,
        r: 110,
        index: 7,
        color: '#0452CE',
        shape: 'half',
        color1: '#43D0E0',
      }, // 左下半圆
      {
        x: width * 0.5 + 190,
        y: height + 8,
        r: 42,
        index: 9,
        color: '#43E0C1',
        shape: 'triangle',
        color1: '#04C3CE',
      }, // 底部三角
    ];

    const drawParam = isMobile ? 0.4 : 1;
    const oriPositionsMobile = [
      {
        x: 200 * drawParam,
        y: 252 * drawParam,
        r: 140 * drawParam,
        index: 0,
        color: '#FF558B',
        color1: '#FF91AD',
        direction: 'horizontal',
      }, // 左上大
      {
        x: 100 * drawParam,
        y: 162 * drawParam,
        r: 50 * drawParam,
        index: 1,
        color: '#5B98FF',
        color1: '#89C7FB',
        direction: 'horizontal',
      }, // 左上小
      {
        x: width - 250 * drawParam,
        y: -8,
        r: 70 * drawParam,
        index: 2,
        color: '#7471FC',
        shape: 'ring',
        color1: '#4FC3E7',
      }, // 右上的环
      {
        x: width - 230 * drawParam,
        y: height * 0.5 + 100 * drawParam,
        r: 180 * drawParam,
        index: 3,
        color: '#B6FC9A',
        color1: '#85E2EB',
      }, // 右大
      {
        x: width - 180 * drawParam,
        y: height * 0.5,
        r: 90 * drawParam,
        index: 4,
        color: '#FDE592',
        color1: '#FE9E65',
      }, // 右小
      {
        x: 70 * drawParam,
        y: height * 0.55 + 90 * drawParam,
        r: 35 * 0.8,
        index: 5,
        color: '#152A3B',
        shape: 'logo',
      }, // logo
      {
        x: width * 0.5 - 10 * drawParam,
        y: 280 * drawParam,
        r: 30 * drawParam,
        index: 6,
        color: '#CDEE9B',
        color1: '#9AE988',
      }, // 中上
      {
        x: 390 * drawParam,
        y: height - 214 * drawParam,
        r: 110 * drawParam,
        index: 8,
        color: '#04C3CE',
        color1: '#43E0C1',
      }, // 左下圆
      {
        x: 200 * drawParam,
        y: height - 202 * drawParam,
        r: 110 * drawParam,
        index: 7,
        color: '#0452CE',
        shape: 'half',
        color1: '#43D0E0',
      }, // 左下半圆
      {
        x: width * 0.5 + 190 * drawParam,
        y: height + 8 * drawParam,
        // y: height - 150,
        r: 42,
        index: 9,
        color: '#43E0C1',
        shape: 'triangle',
        color1: '#04C3CE',
      }, // 底部三角
    ];

    // 鼠标环
    const mouseCircle = {
      currX: width / 2,
      currY: -100,
      targX: width / 2,
      targY: -100,
      rds: 40 * drawParam,
      easingCircle: 0.15,
      easingRect: 0.13,
      rectCurrX: width / 2,
      rectCurrY: -100,
    };

    const activeDistanceRadius = isMobile ? 300 : 1200;

    function generateCirclesInCircle() {
      const selfCircles = [];
      (isMobile ? oriPositionsMobile : oriPositions).forEach((item, idx) => {
        selfCircles.push(
          new Circle(item, idx, {
            logoLoaded,
            img,
            ctx,
            setLogoLoaded,
            canvas,
          })
        );
      });
      circles = [...selfCircles];
      ball = new Ball(logo2Loaded, ballImag, ctx2, setLogo2Loaded, canvas2);
    }

    function generateNewWorld() {
      ctx.strokeStyle = '#333';
      ctx.fillStyle = 'transparent';
      generateCirclesInCircle();
    }

    function updateCirclesPos() {
      const newCircles = circles.map((circleItem) => {
        const theta = Math.atan2(
          circleItem.y - mouseCircle.currY,
          circleItem.x - mouseCircle.currX
        );
        // TODO 修改推动效果
        const distance =
          activeDistanceRadius /
          dist(mouseCircle.currX, mouseCircle.currY, circleItem.x, circleItem.y);
        const alpha = circleItem.shape === 'triangle' ? 2.5 : 1;
        circleItem.x += Math.cos(theta) * distance * alpha + (circleItem.origX - circleItem.x) * 0.075;
        circleItem.y += Math.sin(theta) * distance * alpha + (circleItem.origY - circleItem.y) * 0.075;
        return circleItem;
      });
      circles = [...newCircles];
    }

    function drawFoamBubbles() {
      updateCirclesPos(circles);
      circles.forEach(function (p) {
        p.updateAnimationValues();
      });
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      circles.forEach(function (p) {
        p.drawCircle(drawParam);
      });
      ctx2.clearRect(0, 0, canvas2.width, canvas2.height);
      ball.drawCircle(drawParam);
    }

    function drawMouseCircle() {
      mouseCircle.currX += (mouseX - mouseCircle.currX) * mouseCircle.easingCircle;
      mouseCircle.currY += (mouseY - mouseCircle.currY) * mouseCircle.easingCircle;

      mouseCircle.rectCurrX += (mouseX - mouseCircle.rectCurrX) * mouseCircle.easingRect;
      mouseCircle.rectCurrY += (mouseY - mouseCircle.rectCurrY) * mouseCircle.easingRect;

      ctx.beginPath();
      ctx.lineWidth = 1;
      ctx.strokeStyle = '#333';
      ctx.arc(mouseCircle.currX, mouseCircle.currY, mouseCircle.rds, 0, Math.PI * 2, false);
      ctx.stroke();

      ctx.save();
      ctx.restore();
    }

    function draw() {
      // background(bgSvg, ctx, canvas);
      // background(bgSvg, ctx2, canvas2);
      drawFoamBubbles();
      drawMouseCircle();
      if (animationFrame) {
        animationFrame(draw); // 按帧对网页进行重绘
      }
    }

    function setup() {
      canvas.width = width;
      canvas.height = height;
      canvas2.width = width;
      canvas2.height = height;
      ctx.lineWidth = 1;
      ctx2.lineWidth = 1;
      generateNewWorld();
      draw();
    }
    setup();
  }

  function setEventListeners() {
    document.addEventListener('mousemove', mouseMove);
    window.addEventListener('resize', function () {
      const w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      const h = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
      setWidth(w);
      setHeight(h - topMargin > 400 ? h - topMargin : 400);
      if (canvas) {
        canvas.width = w;
        canvas.height = h > 400 ? h : 400;
      }
    });
  }

  function init(animationFrame) {
    const canvasEle = document.getElementById('canvas1');
    setCanvasEle(canvasEle);
    const canvasEle2 = document.getElementById('canvas2');
    setCanvasEle2(canvasEle2);
    setCanvas(animationFrame);
    setEventListeners();
  }

  useEffect(() => {
    const animationFrame = (function () {
      return (
        window.requestAnimationFrame ||
        window.webkitRequestAnimationFrame ||
        window.mozRequestAnimationFrame ||
        function (callback) {
          window.setTimeout(callback, 1000 / 60);
        }
      );
    })();
    init(animationFrame);
  }, [logoLoaded]);

  useEffect(() => {
    init();
  }, [canvas, canvas2, width, height]);

  // 快速生成
  const onSearch = () => {
    if (!value) {
      setError(true);
      input.current.blur();
      setTimeout(() => {
        setError(false);
      }, 500);
      return;
    }
    // TODO 图形散开后，cards-wrapper上升; 反之，回到第一步时，先落下cards-wrapper，再把图形移回窗口内
    if (isMobile) {
      input.current.blur();
      setTimeout(() => {
        setVisible(true);
        if (setCardsWrapperShown) setCardsWrapperShown(false);
      }, 300);
    } else {
      setVisible(true);
      if (setCardsWrapperShown) setCardsWrapperShown(false);
    }
    downloadCounts++;
  };

  // 重新输入
  const onBackClick = () => {
    setVisible(false);
    setMovingDirection('');
    setPrevCardIndex(0);
    setCurrentCardIndex(0);
    input.current.blur();
    setValue('');
  };

  // 切换卡片的按钮
  function onButtonClick(direction) {
    if (direction === 'right') {
      if (currentCardIndex === 5) {
        return;
      }
      setCurrentCardIndex(currentCardIndex + 1);
      setPrevCardIndex(currentCardIndex);
      setMovingDirection(direction);
    } else {
      if (currentCardIndex === 0) {
        return;
      }
      setMovingDirection(direction);
      setCurrentCardIndex(currentCardIndex - 1);
      setPrevCardIndex(currentCardIndex);
    }
  }

  const cardsData = downloadCounts % 3 === 1 ? CARDS_DATA1 : downloadCounts % 3 === 2 ? CARDS_DATA2 : CARDS_DATA;

  // const handleCanvasMove = () => {
  //   console.log('handleCanvasMove');
  // };

  return (
    <Layout current="sources" subCurrent="yourtitles" type="tool">
      <main className="your-titles" style={isMobile ? { height } : {}}>
        <canvas className="canvas" id="canvas1" />
        <div className="content-wrapper">
          <div className="content-inner">
            {/*<Button onClick={handleCanvasMove}>test</Button>*/}
            <h1>你想要的好标题都在这里</h1>
            <h3>一秒钟生成200个创意标题</h3>
            <Search
              placeholder="输入一个名词开始搜索"
              enterButton="快速生成"
              size="large"
              value={value}
              maxLength={20}
              className={error ? 'error' : ''}
              onSearch={onSearch}
              onPressEnter={onSearch}
              onChange={onChange}
              ref={input}
            />
          </div>
        </div>
        <div className={`cards-wrapper ${cardsWrapperVisible ? 'show' : ''} ${cardsWrapperUnshown ? 'unshown' : ''}`}>
          <canvas className="canvas" id="canvas2" />
          <Container>
            <div className="back" onClick={onBackClick}>
              <Arrow />
              <span className="back-content">重新输入</span>
            </div>
            <div className="cards-content">
              <img src={LogoImage} className="title-logo" alt="convertlab" />
              <div className="card-list">
                {movingDirection !== 'left' && <CardItem {...cardsData[currentCardIndex]} value={value} />}
                {movingDirection === 'left' && <CardItem {...cardsData[currentCardIndex + 1]} value={value} />}
                {prevCardIndex <= currentCardIndex && (
                  <CardItem
                    {...cardsData[prevCardIndex]}
                    key={prevCardIndex}
                    value={value}
                    animate
                    moving
                    movingDirection={movingDirection}
                  />
                )}
                {cardsData.map((card, index) => (
                  <CardItem
                    {...card}
                    key={card.id}
                    value={value}
                    animate
                    moving={movingDirection === 'left' && index === currentCardIndex}
                    movingDirection={movingDirection}
                  />
                ))}
                {cardsData.map((card, index) => (
                  <CardItem
                    {...card}
                    key={card.id}
                    value={value}
                    index={index - currentCardIndex}
                    fake
                  />
                ))}
                <div className="card-counter">{currentCardIndex + 1} / 6</div>
                <div
                  className={`card-button left current-${currentCardIndex}`}
                  onClick={() => onButtonClick('left')}
                >
                  <Arrow />
                </div>
                <div
                  className={`card-button right current-${currentCardIndex}`}
                  onClick={() => onButtonClick('right')}
                >
                  <Arrow />
                </div>
              </div>
            </div>
          </Container>
        </div>
      </main>
    </Layout>
  );
};
